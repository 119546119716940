import { defineMessages } from "react-intl.macro";
export default defineMessages({
  noResultsHeader: {
    id: "integration.no.results.header",
    defaultMessage: "No results found"
  },
  noResultsText: {
    id: "integration.no.results.text",
    defaultMessage: "We found no results that match your search criteria."
  }
});
