import moment from "moment";

function getHeaderItem(message, headerName) {
  return message.payload.headers.find(item => item.name === headerName) || {};
}

function isMessageChat(message) {
  return message.labelIds.includes("CHAT");
}

function isMessageUnread(message) {
  return message.labelIds.includes("UNREAD");
}

// Return only what is needed
export function parseMessage(message) {
  const isChat = isMessageChat(message);

  const subjectItem = getHeaderItem(message, "Subject");
  const subject = subjectItem.value || (isChat ? "Chat" : "No subject");
  const fromItem = getHeaderItem(message, "From");
  const from = fromItem.value || "";

  let name = from;
  let email = from;
  if (from.includes("<")) {
    name = from.substr(0, from.indexOf("<")).trim();
    email = from
      .substr(from.indexOf("<"), from.length)
      .replace("<", "")
      .replace(">", "");
  }

  const toItem = getHeaderItem(message, "To");
  const to = toItem.value;
  const dateItemm = getHeaderItem(message, "Date");
  const date =
    dateItemm.value ||
    (isChat
      ? moment(message.internalDate, "x").format("DD MMM YYYY hh:mm a")
      : null);
  const url = `https://mail.google.com/mail/u/0/#inbox/${message.id}`;
  return {
    id: message.id,
    subject,
    snippet: message.snippet,
    from: {
      name: name || email,
      email
    },
    to,
    sent: date,
    date,
    url,
    isChat,
    isUnread: isMessageUnread(message)
  };
}

export async function list(query = {}, options = {}) {
  try {
    options = {
      maxResults: 10,
      ...options
    };

    if (query.from) {
      query.q = query.q + " AND from:" + query.from;
    }

    if (query.to) {
      query.q = query.q + " AND to:" + query.to;
    }

    if (query.before) {
      query.q =
        query.q + " AND before:" + moment(query.before).format("YYYY/MM/DD");
    }

    if (query.after) {
      query.q =
        query.q + " AND after:" + moment(query.after).format("YYYY/MM/DD");
    }

    const data = await window.gapi.client.gmail.users.messages.list({
      userId: "me",
      q: query.q,
      labelIds: options.labelIds,
      pageToken: options.pageToken,
      maxResults: options.maxResults
    });
    if (data.error) throw Error(data.error);

    let messages = [];
    if (data.result.messages) {
      messages = await Promise.all(
        data.result.messages.map(async item => {
          return await get(item);
        })
      );
    }
    return {
      resultCount: data.result.resultSizeEstimate,
      nextPageToken: data.result.nextPageToken,
      items: messages
    };
  } catch (error) {
    throw error;
  }
}

export async function get({ id }) {
  try {
    const data = await window.gapi.client.gmail.users.messages.get({
      userId: "me",
      id,
      format: "metadata"
    });

    if (data.error) throw Error(data.error);
    return parseMessage(data.result);
  } catch (error) {
    throw error;
  }
}

export async function getVacation() {
  try {
    const data = await window.gapi.client.gmail.users.settings.getVacation({
      userId: "me"
    });
    if (data.error) throw Error(data.error);
    return data.result;
  } catch (error) {
    throw error;
  }
}

export function isAuthorizationRequired(error) {
  return [401, 403].includes(error.status);
}
