import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ROUTE_PATHNAMES, SENTRY_CONF } from "./constants";
import { OauthCallback } from "./OAuthCallback";

// Init Sentry
Sentry.init(SENTRY_CONF);
window.randomKey = Math.random();
if (window.location.pathname.includes(ROUTE_PATHNAMES.oauthCallback)) {
  ReactDOM.render(<OauthCallback />, document.getElementById("root"));
} else {
  ReactDOM.render(<App />, document.getElementById("root"));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
