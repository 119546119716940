import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { list, isAuthorizationRequired } from "../../services/GmailApi/api";
import { AuthorizationMessage } from "../Authorization";
import {
  trackEvent,
  SEARCHED_GMAIL,
  UNAUTHORIZED_SEARCH,
} from "../AppAnalytics";
import {
  RECEIVED_RESULTS,
  SEARCH_GMAIL,
  RESIZE_EVENT,
} from "../../services/iframeCommunication/actions";
import {
  authorizeEvent,
  emitToParent,
} from "../../services/iframeCommunication";
import messages from "./messages";
import { useIntl } from "react-intl";

const SearchResults = () => {
  const intl = useIntl();
  const [query, setQuery] = useState({});
  const [hideQ, setHideQ] = useState(null);
  const [requestAuthorization, setRequestAuthorization] = useState(false);

  useEffect(() => {
    function listener(event) {
      authorizeEvent(event).then((allowed) => {
        if (!allowed) return;
        const { data } = event;

        if (data[SEARCH_GMAIL]) {
          setQuery(() => data[SEARCH_GMAIL]);
        }
      });
    }

    window.addEventListener("message", (event) => listener(event), false);
    return window.removeEventListener("message", listener);
  }, []);

  useEffect(() => {
    if (!query.q) return;

    list(query, {
      maxResults: query.maxResults,
      pageToken: query.nextPageToken,
    })
      .then((results) => {
        emitToParent({ [RECEIVED_RESULTS]: JSON.stringify(results) });
        trackEvent(SEARCHED_GMAIL, {}, {});
      })
      .catch((error) => {
        if (isAuthorizationRequired(error)) {
          setRequestAuthorization(true);
          setHideQ(query);
          setQuery(false);
          trackEvent(UNAUTHORIZED_SEARCH, {}, { onlyUniverse: true });
        }
      });
  }, [query]);

  const authorizationCallback = () => {
    setRequestAuthorization(false);
    setQuery(hideQ);

    emitToParent({ [RESIZE_EVENT]: { height: "0px" } });
  };

  if (requestAuthorization) {
    return (
      <Container>
        <AuthorizationMessage
          title={intl.formatMessage(messages.authorizeTitle)}
          content={intl.formatMessage(messages.authorizeContent)}
          scope={"search"}
          callback={authorizationCallback}
        />
      </Container>
    );
  }

  return null;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default SearchResults;
