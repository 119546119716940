import React, { Fragment } from "react";
import styled from "styled-components";
import moment from "moment";
import { useIntl } from "react-intl";
import { BodyUI } from "@happeouikit/typography";
import { Spacer } from "@happeouikit/layout";
import { Badge } from "@happeouikit/form-elements";
import { gray07, active, gray03, gray05 } from "@happeouikit/colors";
import { radius500, fadeInOut } from "@happeouikit/theme";
import messages from "./messages";

const GmailInboxItemLoader = () => (
  <Container>
    <TitleContainer>
      <Loader width={"60%"} />
    </TitleContainer>
    <ContentContainer>
      <Loader width={"80%"} />
    </ContentContainer>
  </Container>
);

const GmailInboxItem = ({ message, loading }) => {
  const intl = useIntl();

  if (loading) {
    return <GmailInboxItemLoader />;
  }

  const date = moment(message.date);
  let timestamp;
  if (date.isValid()) {
    if (date.isSame(moment(), "day")) {
      timestamp = date.fromNow();
    } else {
      timestamp = date.format("LLL");
    }
  }

  return (
    <Container href={message.url} target={"_blank"}>
      <TitleContainer className={"subject"}>
        <BodyUI bold>{message.subject}</BodyUI>
      </TitleContainer>
      <ContentContainer>
        <ContentLeft>
          {message.isUnread && (
            <Fragment>
              <Badge
                text={intl.formatMessage(messages.newEmailMessage)}
                isFilled
              />
              <Spacer width={"6px"} />
            </Fragment>
          )}
          <BodyUI color={gray03}>{message.from.name}</BodyUI>
        </ContentLeft>
        <ContentRight>
          {timestamp && <BodyUI color={gray05}>{timestamp}</BodyUI>}
        </ContentRight>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.a`
  display: block;
  padding: 8px;
  text-decoration: none;
  :hover .subject p {
    color: ${active};
  }
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
    white-space: nowrap;
  }
`;
const TitleContainer = styled.div``;
const ContentLeft = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  min-width: 0;
`;
const ContentRight = styled.div`
  flex-shrink: 0;
  margin-left: 4px;
`;
const ContentContainer = styled.div`
  margin-top: 4px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
`;
const Loader = styled.div`
  height: 14px;
  border-radius: ${radius500};
  width: ${(props) => props.width};
  background-color: ${gray07};
  animation: ${fadeInOut} 2000ms linear infinite;
`;

export default GmailInboxItem;
