import { defineMessages } from "react-intl.macro";
export default defineMessages({
  enableIntegrationTitle: {
    id: "enableIntegration.title",
    defaultMessage: "Enable the Gmail integration to view your emails"
  },
  enableOutOfOfficeIntegrationTitle: {
    id: "enableOutOfOfficeIntegration.title",
    defaultMessage: "Enable the Gmail integration to import your settings"
  },
  enableIntegrationContent: {
    id: "enableIntegration.content",
    defaultMessage:
      "To view your emails in Happeo you need to enable the Gmail Integration. Read more {link}."
  },
  enableOutOfOfficeIntegrationContent: {
    id: "enableOutOfOfficeIntegration.content",
    defaultMessage:
      "To import your out of office settings to Happeo you need to enable the Gmail Integration."
  },
  enableIntegrationButtonEnable: {
    id: "enableIntegration.button.enable",
    defaultMessage: "Enable Integration"
  },
  readMoreHere: {
    id: "enableIntegration.readMore.here",
    defaultMessage: "here"
  }
});
