import { defineMessages } from "react-intl.macro";
export default defineMessages({
  authorizeTitle: {
    id: "outOfOffice.authorization.title",
    defaultMessage: "Google Gmail integration is not authorized"
  },
  authorizeContent: {
    id: "outOfOffice.authorization.content",
    defaultMessage:
      "This integration needs to be authorized in order for Happeo to import your out of office settings from your Gmail."
  }
});
