import { SCOPES } from "../../constants";
import { gisUtils } from "../googleIdentity/GisUtils";

function requestScopes(scopeString, callback) {
  gisUtils.requestAccessToken(SCOPES[scopeString], callback);
}

export function requestAllScopes(callback) {
  return requestScopes("all", callback);
}

export function requestSearchScopes(callback) {
  return requestScopes("search", callback);
}

export function requestSettingsScopes(callback) {
  return requestScopes("settings", callback);
}

export function requestInboxScopes(callback) {
  return requestScopes("inbox", callback);
}