import loadjs from "loadjs";
import {
  API_KEY,
  CLIENT_ID,
  DISCOVERY_DOCS,
  ROUTE_PATHNAMES,
  ROUTE_SCOPE_MAP,
  SCOPES,
} from "../../constants";
import { NEW_ACCESS_TOKEN } from "../iframeCommunication/actions";

class GisUtils {
  ACCESS_TOKEN_NAME = "GOOGLE_AUTH_TOKEN";
  isReady = false;
  isLoggedIn = !!localStorage.getItem(this.ACCESS_TOKEN_NAME);
  authStatusChangeCallbacks = [];
  onReadyCbQueue = [];
  tokenRefreshHandlerTimeoutId = -1;

  constructor() {
    if (window.location.toString().includes(ROUTE_PATHNAMES.oauthCallback)) {
      return;
    }
    const librariesToLoad = [
      "https://apis.google.com/js/api.js",
      "https://accounts.google.com/gsi/client",
    ];
    this._handleLibraryLoad = this._handleLibraryLoad.bind(this);
    this._initializeGapiClient = this._initializeGapiClient.bind(this);
    this.refreshToken = this.refreshToken.bind(this);
    this.processOnReadyCallbackQueue =
      this.processOnReadyCallbackQueue.bind(this);
    this.addTokenRefreshHandler = this.addTokenRefreshHandler.bind(this);
    loadjs(librariesToLoad, "googleLibraries");
    loadjs.ready("googleLibraries", () => this._handleLibraryLoad());
  }

  _initializeGapiClient() {
    window.gapi.client
      .init({
        apiKey: API_KEY,
        discoveryDocs: DISCOVERY_DOCS,
      })
      .then(() => {
        this.isReady = true;

        const savedToken = this.getSavedAccessToken();
        if (savedToken) {
          this.setAccessTokenForGapiClient();
        } else {
          //no access token found process ready queue
          this.processOnReadyCallbackQueue();
        }
        console.log(window.gapi, window.google);
      })
      .catch(console.error);
  }

  _handleLibraryLoad() {
    window.gapi.load("client", this._initializeGapiClient);
  }

  onAuthStatusChange(callback) {
    if (callback && typeof callback === "function") {
      this.authStatusChangeCallbacks.push(callback);
    }
  }

  invokeCallbacks(authStatus) {
    this.authStatusChangeCallbacks.forEach((callback) => {
      callback(authStatus);
    });
  }

  removeSavedAccessToken() {
    localStorage.removeItem(this.ACCESS_TOKEN_NAME);
    this.invokeCallbacks(false);
  }

  getSavedAccessToken(params) {
    const savedData = localStorage.getItem(this.ACCESS_TOKEN_NAME);
    if (savedData) {
      return JSON.parse(savedData);
    }
  }

  saveAccessToken(accessToken) {
    if (accessToken) {
      localStorage.setItem(this.ACCESS_TOKEN_NAME, JSON.stringify(accessToken));
      this.invokeCallbacks(true);
    }
  }

  injectExpiryTimeStampToTokenObject(tokenObj) {
    if (tokenObj && tokenObj.expires_in) {
      const tokenRefrshIntervalMs = tokenObj.expires_in * 1000;

      // for local development(token refreshed every 20 seconds)
      // const tokenRefrshIntervalMs = 5 * 1000;

      // adding expiry timestamp so that we can trigger token refresh silently
      tokenObj.expiryTimeStamp = Date.now() + tokenRefrshIntervalMs;
    }
  }

  requestAccessToken(scopes, callback) {
    const config = {
      client_id: CLIENT_ID,
      scope: scopes,
      prompt: "",
      hint: window.loggedInUserEmail,
      callback: (accessTokenObject) => {
        // accessTokenObject.expires_in  is in seconds and must be converted to MS
        this.injectExpiryTimeStampToTokenObject(accessTokenObject);
        accessTokenObject.login_hint = window.loggedInUserEmail;
        this.isLoggedIn = true;
        this.saveAccessToken(accessTokenObject);
        this.setAccessTokenForGapiClient();
        callback(true);
      },
      error_callback: (error) => {
        console.error(error);
        callback(false);
      },
    };
    const gisTokenClient =
      window.google.accounts.oauth2.initTokenClient(config);

    gisTokenClient.requestAccessToken();
  }

  onReady(cb) {
    if (typeof cb === "function") {
      if (this.isReady) {
        cb();
      } else {
        this.onReadyCbQueue.push(cb);
      }
    }
  }

  addTokenRefreshHandler(accessToken) {
    const tokenValidityPeriod = accessToken.expiryTimeStamp - Date.now();
    clearTimeout(this.tokenRefreshHandlerTimeoutId);
    this.tokenRefreshHandlerTimeoutId = setTimeout(() => {
      this.refreshToken();
    }, tokenValidityPeriod);
  }

  refreshToken() {
    const scope = ROUTE_SCOPE_MAP[window.location.pathname] || SCOPES.basic;
    const callBack = () => {
      console.log("gsuite integration token refreshed", scope);
    };
    // this.requestAccessToken(scope, callBack);
    this.silentSignIn(scope, callBack);
  }

  isTokenExpied(accessToken) {
    return Date.now() >= accessToken.expiryTimeStamp;
  }

  setAccessTokenForGapiClient() {
    const accessToken = this.getSavedAccessToken();
    if (!this.isReady) {
      throw new Error("Token being set before client ready");
    }
    if (!accessToken) {
      throw new Error("Token being set before it was saved");
    }
    if (accessToken) {
      if (this.isTokenExpied(accessToken)) {
        this.refreshToken();
      } else {
        window.gapi.client.setToken(accessToken);
        this.addTokenRefreshHandler(accessToken);
        // processing on ready queue since refresh not needed
        // or will be called if the token refresh happens and no refresh will be needed
        this.processOnReadyCallbackQueue();
      }
    }
  }

  processOnReadyCallbackQueue() {
    this.onReadyCbQueue.forEach((cb) => {
      cb();
    });
    this.onReadyCbQueue = [];
  }

  silentSignIn(scope, callBack) {
    if (this.tokenRefreshHandlerTineoutId) {
      clearTimeout(this.tokenRefreshHandlerTineoutId);
    }
    // Google's OAuth 2.0 endpoint for requesting an access token
    const oauth2Endpoint = "https://accounts.google.com/o/oauth2/v2/auth?";

    if (window.location.origin.startsWith(oauth2Endpoint)) {
      return;
    }

    let login_hint = window.loggedInUserEmail;
    let expiredTokenObj = {};
    if (!login_hint) {
      try {
        expiredTokenObj = this.getSavedAccessToken();
        login_hint = expiredTokenObj.login_hint;
        if (!login_hint) {
          throw new Error("user email missing, prompt(popup) will open");
        }
      } catch {
        this.requestAccessToken(scope, callBack);
      }
    }

    // Create <form> element to submit parameters to OAuth 2.0 endpoint.
    const form = document.createElement("form");
    form.setAttribute("method", "GET"); // Send as a GET request.
    form.setAttribute("action", oauth2Endpoint);

    // Parameters to pass to OAuth 2.0 endpoint.
    const params = {
      client_id: CLIENT_ID,
      redirect_uri: window.location.origin + ROUTE_PATHNAMES.oauthCallback,
      response_type: "token",
      scope: scope,
      include_granted_scopes: "true",
      state: "",
      login_hint,
    };
    const hiddenIframe = document.createElement("iframe");
    hiddenIframe.style.display = "none";
    hiddenIframe.src = oauth2Endpoint + new URLSearchParams(params).toString();

    const processNewToken = (event) => {
      window.removeEventListener("message", processNewToken);
      if (window.location.origin === event.origin) {
        if (event.data && event.data[NEW_ACCESS_TOKEN]) {
          hiddenIframe.remove();
          const newToken = event.data[NEW_ACCESS_TOKEN];
          this.injectExpiryTimeStampToTokenObject(newToken);
          //this is done to preserve some extra props such as email saved as a part
          // of token object earlier and unavailable after refresh
          const combinedTokenObj = {
            ...expiredTokenObj,
            ...newToken,
            login_hint,
          };
          this.saveAccessToken(combinedTokenObj);
          console.log(
            "🚀 ~ file: GisUtils.js:253 ~ GisUtils ~ processNewToken ~ combinedTokenObj:",
            combinedTokenObj
          );
          this.setAccessTokenForGapiClient();
        }
      }
    };

    window.addEventListener("message", processNewToken);
    document.body.appendChild(hiddenIframe);
  }
}
export const gisUtils = new GisUtils();
