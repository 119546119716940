import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import messages from "./messages";
import { ASSETS_ROOT } from "../../constants";
import { TextEpsilon, BodyUI } from "@happeouikit/typography";
import { Spacer } from "@happeouikit/layout";
import { gray04 } from "@happeouikit/colors";
import { useIntl } from "react-intl";

/**
 *
 * NoResults
 *
 */
const NoResults = ({ title, description }) => {
  const src = "no-results.svg";
  const intl = useIntl();

  title = title || intl.formatMessage(messages.noResultsHeader);
  description = description || intl.formatMessage(messages.noResultsText);

  return (
    <Container>
      <Img src={`${ASSETS_ROOT}/${src}`} alt="No results" />
      <Spacer />
      <TextEpsilon bold>{title}</TextEpsilon>
      <Spacer />
      <BodyUI color={gray04}>{description}</BodyUI>
    </Container>
  );
};

NoResults.propTypes = {
  type: PropTypes.string,
  clearFilters: PropTypes.func,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 40px;
`;

const Img = styled.img`
  height: 90px;
`;
export default NoResults;
