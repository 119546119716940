// eslint-disable-next-line
import React, { useEffect } from "react";
import {
  requestAllScopes,
  requestSearchScopes,
  requestSettingsScopes,
  requestInboxScopes,
} from "../../services/GmailApi/initialisation";

import {
  AUTHORIZE_EVENT,
  AUTHORIZE_EVENT_DONE,
} from "../../services/iframeCommunication/actions";
import {
  authorizeEvent,
  emitToParent,
} from "../../services/iframeCommunication";

const AuthorizationListener = () => {
  useEffect(() => {
    function listener(event) {
      authorizeEvent(event).then((allowed) => {
        if (!allowed) return;
        const { data } = event;
        if (data[AUTHORIZE_EVENT]) {
          authorize(data[AUTHORIZE_EVENT].scope, (isAuthSuccess) => {
            // maps to this line in react-ng-bridge https://bitbucket.org/getuniverse/react-ng-bridge/src/823286a9b00fc2232cb7e58a463c56e7f5f35af5/src/components/Integrations/Integration.js#Integration.js-240:240
            emitToParent({
              [AUTHORIZE_EVENT_DONE]: {
                fail: !isAuthSuccess,
                success: isAuthSuccess,
              },
            });
          });
        }
      });
    }

    window.addEventListener("message", (event) => listener(event), false);
    return window.removeEventListener("message", listener);
  }, []);

  const authorize = (scope = "all", callback) => {
    let fn = () => {
      console.error("Auth scope missing");
    };

    if (scope === "all") {
      fn = requestAllScopes;
    }

    if (scope === "search") {
      fn = requestSearchScopes;
    }

    if (scope === "settings") {
      fn = requestSettingsScopes;
    }

    if (scope === "inbox") {
      fn = requestInboxScopes;
    }

    fn(callback);
  };
  return null;
};
export default AuthorizationListener;
