import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { trackEvent, UNAUTHORIZED_OUT_OF_OFFICE } from "../AppAnalytics";
import {
  getVacation,
  isAuthorizationRequired,
} from "../../services/GmailApi/api";
import {
  emitToParent,
  authorizeEvent,
} from "../../services/iframeCommunication";

import {
  AUTHORIZATION_STATUS,
  RECEIVED_RESULTS,
  RESIZE_EVENT,
  GET_VACATION_MESSAGE,
} from "../../services/iframeCommunication/actions";
import { AuthorizationMessage } from "../Authorization";
import messages from "./messages";
import { gisUtils } from "../../services/googleIdentity/GisUtils";

const OutOfOfficeImport = () => {
  const intl = useIntl();
  const [requestAuthorization, setRequestAuthorization] = useState(false);

  function getVacationSuccess(message) {
    emitToParent({ [RECEIVED_RESULTS]: JSON.stringify(message) });
  }

  function getVacationError(error) {
    if (isAuthorizationRequired(error)) {
      setRequestAuthorization(true);
      trackEvent(UNAUTHORIZED_OUT_OF_OFFICE, {}, { onlyUniverse: true });
    }
  }

  useEffect(() => {
    gisUtils.onReady(() => {
      getVacation()
        .then(() => {
          emitToParent({ [AUTHORIZATION_STATUS]: { authorized: true } });
        })
        .catch(getVacationError);
    });

    function listener(event) {
      authorizeEvent(event).then((allowed) => {
        if (!allowed) return;
        const { data } = event;
        if (data[GET_VACATION_MESSAGE]) {
          getVacation().then(getVacationSuccess).catch(getVacationError);
        }
      });
    }

    window.addEventListener("message", (event) => listener(event), false);
    return window.removeEventListener("message", listener);
  }, []);

  const authorizationCallback = () => {
    setRequestAuthorization(false);
    getVacation().then(getVacationSuccess);
    emitToParent({ [RESIZE_EVENT]: { height: "0px" } });
  };

  if (requestAuthorization) {
    return (
      <Container>
        <AuthorizationMessage
          title={intl.formatMessage(messages.authorizeTitle)}
          content={intl.formatMessage(messages.authorizeContent)}
          scope={"settings"}
          type={"banner"}
          callback={authorizationCallback}
        />
      </Container>
    );
  }
  return null;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default OutOfOfficeImport;
