import { defineMessages } from "react-intl.macro";
export default defineMessages({
  authorizeTitle: {
    id: "inbox.authorization.title",
    defaultMessage: "Google Gmail integration is not authorized"
  },
  authorizeContent: {
    id: "inbox.authorization.content",
    defaultMessage:
      "This integration needs to be authorized in order for Happeo to show you your latest emails."
  },
  openGmail: {
    id: "inbox.link.openGmail",
    defaultMessage: "Open Gmail"
  },
  updatingInbox: {
    id: "inbox.updating",
    defaultMessage: "Updating"
  },
  genericError: {
    id: "inbox.error.generic",
    defaultMessage: "Something went wrong"
  },
  noResultsTitle: {
    id: "inbox.empty.title",
    defaultMessage: "No emails found"
  },
  noResultsDescription: {
    id: "inbox.empty.description",
    defaultMessage: "Your inbox seems to empty"
  },
  newEmailMessage: {
    id: "inbox.message.new",
    defaultMessage: "New"
  }
});
