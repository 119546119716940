import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { Spacer } from "@happeouikit/layout";
import { Banner } from "@happeouikit/banners";
import { gray04, gray07 } from "@happeouikit/colors";
import { ButtonPrimary } from "@happeouikit/buttons";
import { BodyUI, TextDelta } from "@happeouikit/typography";
import { IconLock } from "@happeouikit/icons";
import { FormattedMessage } from "react-intl.macro";
import {
  ENABLE_INTEGRATION,
  RESIZE_EVENT,
} from "../../services/iframeCommunication/actions";
import { emitToParent } from "../../services/iframeCommunication";
import messages from "./messages";
import { Link } from "@happeouikit/form-elements";

const IntegrationToggleMessage = ({ type = "ad" }) => {
  const containerElement = useRef(null);
  const intl = useIntl();

  useEffect(() => {
    if (!containerElement || !containerElement.current) return;
    emitToParent({
      [RESIZE_EVENT]: {
        height: `${containerElement.current.offsetHeight + 2}px`,
      },
    });
  });

  const clickEnableIntegration = () => {
    emitToParent({ [ENABLE_INTEGRATION]: true });
  };

  if (type === "banner") {
    const title = intl.formatMessage(
      messages.enableOutOfOfficeIntegrationTitle
    );
    const description = intl.formatMessage(
      messages.enableOutOfOfficeIntegrationContent
    );
    return (
      <BannerContainer ref={containerElement}>
        <Banner
          state={"info"}
          title={title}
          description={description}
          action={
            <Link href="#" onClick={clickEnableIntegration}>
              {intl.formatMessage(messages.enableIntegrationButtonEnable)}
            </Link>
          }
        ></Banner>
      </BannerContainer>
    );
  }

  return (
    <Container ref={containerElement}>
      <AuthorizationContainer>
        <IconLock fill={gray07} />
        <Spacer height={"16px"} />
        <TextDelta>
          {intl.formatMessage(messages.enableIntegrationTitle)}
        </TextDelta>
        <Spacer height={"8px"} />
        <BodyUI color={gray04}>
          <FormattedMessage
            id="enableIntegration.content"
            defaultMessage="To view your emails in Happeo you need to enable the Gmail Integration from your settings. You may disable the integration at any time. Read more {link}."
            values={{
              link: (
                <a
                  href="https://www.happeo.com/knowledge/gmail-integration"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  {intl.formatMessage(messages.readMoreHere)}
                </a>
              ),
            }}
          />
        </BodyUI>
        <Spacer height={"16px"} />
        <ButtonPrimary
          text={intl.formatMessage(messages.enableIntegrationButtonEnable)}
          onClick={clickEnableIntegration}
        ></ButtonPrimary>
      </AuthorizationContainer>
    </Container>
  );
};
const BannerContainer = styled.div`
  padding: 4px;
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const AuthorizationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 450px;
  text-align: center;
  svg {
    width: 74px;
    height: 74px;
  }
`;

export default IntegrationToggleMessage;
