import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { SearchResults } from "./components/SearchResults";
import { OutOfOfficeImport } from "./components/OutOfOfficeManager";
import { GmailInbox } from "./components/GmailInbox";
import { AuthorizationListener } from "./components/Authorization";
import { IntegrationToggleMessage } from "./components/IntegrationToggleMessage";

import {
  GSUITE_INTEGRATION_READY,
  IDENTIFY_PARENT,
} from "./services/iframeCommunication/actions";
import {
  authorizeEvent,
  emitToParent,
  setTargetOrigin,
} from "./services/iframeCommunication";
import { TranslationsProvider } from "@universe/react-translations";
import { gisUtils } from "./services/googleIdentity/GisUtils";
import { ROUTE_PATHNAMES } from "./constants";

const App = () => {
  const urlParam = new URLSearchParams(window.location.search);
  const loggedInUserEmail = urlParam.get("email");
  window.loggedInUserEmail = loggedInUserEmail;

  const [jsLoaded, setJsLoaded] = useState(false);
  const [parentIdentified, setParentIdentified] = useState(false);
  const [localeData, setLocaleData] = useState({});
  const [type, setType] = useState(null);

  useEffect(() => {
    if (jsLoaded) return;

    const urlParams = new URLSearchParams(window.location.search);

    // Set value or as undefined. If using just null, this won't work
    const locale = urlParams.get("locale") || undefined;
    const dow = urlParams.get("dow") || undefined;
    const clockMode = urlParams.get("clockMode") || undefined;
    const bannerType = urlParams.get("bannerType") || undefined;
    setType(bannerType);
    setLocaleData({ locale, dow, clockMode });
    gisUtils.onReady(() => {
      emitToParent({ [GSUITE_INTEGRATION_READY]: true });
      setJsLoaded(true);
    });
  }, [jsLoaded]);

  useEffect(() => {
    if (parentIdentified) return;

    function listener(event) {
      authorizeEvent(event).then((allowed) => {
        if (!allowed) return;
        const { data } = event;
        if (data[IDENTIFY_PARENT]) {
          setTargetOrigin(event);
          setParentIdentified(true);
          window.removeEventListener("message", listener);
        }
      });
    }

    window.addEventListener("message", (event) => listener(event), false);
    return window.removeEventListener("message", listener);
  }, [parentIdentified]);

  if (!jsLoaded || !parentIdentified) return null;
  return (
    <Router>
      <TranslationsProvider
        {...localeData}
        file={"gsuite-integration-frame.json"}
        loadBeforeRender={false}
      >
        <Switch>
          <Route path={ROUTE_PATHNAMES.oauthCallback}></Route>
          <Route path={ROUTE_PATHNAMES.enableIntegrations}>
            <IntegrationToggleMessage type={type} />
          </Route>
          <Route path={ROUTE_PATHNAMES.authListenerHidden}>
            <AuthorizationListener />
          </Route>
          <Route path={ROUTE_PATHNAMES.outOfOffice}>
            <OutOfOfficeImport />
          </Route>
          <Route path={ROUTE_PATHNAMES.gmailSearch}>
            <SearchResults />
          </Route>
          <Route path={ROUTE_PATHNAMES.gmailInbox}>
            <GmailInbox />
          </Route>
        </Switch>
      </TranslationsProvider>
    </Router>
  );
};

export default App;
