const prodConf = {
  CLIENT_ID:
    "1077721824210-savh83mffh4bvcfjfcu11m30vlo8vuib.apps.googleusercontent.com",
  ALLOWED_ORIGINS: [
    "https://app.happeo.com",
    "https://app.getuniverse.com",
    "https://river.paf.com",
    "https://intranet.aquafil.com",
    "https://universe.gapps.fi",
    "https://universe.trimble.com",
    "https://feather.rovio.com",
    "https://sofia.evac.com",
    "https://kalevantori.kaleva.fi",
    "https://entra.enersense.com",
    "https://bintra.bygghemma.fi",
    "https://cosmos.neste.com",
    "https://jasso.kirjapaja.fi",
    "https://infohub.rovio.com",
    "https://mytruenorth.news",
    "https://lens.bbfc.co.uk",
    "https://happeo.montereycoe.org",
    "https://yleplus.yle.fi",
    "https://ollie.givaudan.com",
    "https://atbconnect.atb.com",
    "https://space.visma.com",
    "https://loop.gant.com",
    "https://agbiomeconnect.com",
    "https://eptmatrix.com",
    "https://intranet.mhpteamsi.com",
    "https://staffhub.uwcsea.edu.sg",
    "https://central.swmc.com",
    "https://theloop.inoc.com",
    "https://cruxconnect.me",
    "https://netanet.mx",
    "https://hub.younginvincibles.org",
    "https://myaurora.tech",
    "https://workspot.happeo.com",
    "https://devtest.happeo.com",
    "https://hq.planful.com",
    "https://mykairoi.com",
    "https://happeo.nordeus.com",
    "https://test-atbconnect.atb.com",
    "https://happypilot.solvay.com",
    "https://pipeline.beyondretail.co.uk",
    "https://intra.teamolivia.se",
    "https://lifeonsmars.smartrecruiters.com",
    "https://minthub.net",
    "https://intranet.ice.it",
    "https://happeo.sambanovasystems.com",
    "https://wearewtv.wtvglobal.com",
    "https://intranet.phobio.com",
    "https://portti.hamina.fi",
    "https://happeo.nextnovate.com",
    "https://engage.ryff.com",
    "https://kadashboard.com",
    "https://happeo.merig.com",
    "https://happeo.formitable.com",
    "https://theclubhouse.huntclub.com",
    "https://my.1337.life",
    "https://my.newamerica.org",
    "https://whalesite.vineyardvines.com",
    "https://connect.randstad.com",
    "https://connect.turnitin.com",
    "https://cortex.doctolib.com",
    "https://weare.distinctiveschools.org",
    "https://scoop.scmp.work",
    "https://one.epiccharterschools.org",
    "https://r2hq.richland2.org",
    "https://ggconnect.com",
    "https://prins.prowise.me",
    "https://landing.rayonier.com",
    "https://galaxy.dataiku.com",
    "https://charlie.trimble.com",
    "https://happeo.fivoshealth.com",
    "https://tewakahuia.callaghaninnovation.govt.nz",
    "https://booztus.com",
    "https://the-link.co.uk",
    "https://kosmos.krylivi.com",
    "https://revnet.rev.com",
    "https://platform.happyhorizon.com",
    "https://intranet.ardc.edu.au",
    "https://h-link.dev",
    "https://oceans.sennder.com",
    "https://happeo.nomobo.tv",
    "https://athena.weareother.ca",
    "https://hub.bluebridgeone.com",
    "https://universumi.info",
    "https://venturenet.venturewell.org",
    "https://compass.croud.com",
    "https://vivanet.vivaldisinterim.be",
    "https://thescrip.leightons.co.uk",
    "https://loom.fabric.inc",
    "https://spark.successacademies.org",
    "https://niateamhub.thenia.org",
    "https://harvest.sevenrooms.com",
    "https://thewell.wellcomww.com",
    "https://intranet.agent3.com",
    "https://pinportal.com",
    "https://dev.pinportal.com",
    "https://connect.colorofchange.org",
    "https://home.lifestylecommunities.com",
    "https://thesauce.saucelabs.com",
    "https://zhub.zetaschools.org",
    "https://intranet.leasequery.com",
    "https://intranet.sysarb.se",
    "https://teammro.com",
    "https://dnephlife.com",
    "https://my.capintel.com",
    "https://evaconnect.evaglobal.com",
    "https://lunarverse.lunar.app",
    "https://team.psymplicity.com",
    "https://myvedrai.internal.vedr.ai",
    "https://intranet.t360.com",
    "https://bigbangenbref.com",
    "https://connect.onixnet.com",
    "https://social.engooden.com",
    "https://me360.mebanking.com",
    "https://happeo.kajabi.com",
    "https://onehub.mobkoi.com",
    "https://one.hugeinc.com",
    "https://backstage.the-works.com",
    "https://hub.harborretail.com",
    "https://happeo.wonde.com",
    "https://lighthouse.hellobrightline.com",
    "https://wiki.ancoris.com",
    "https://employee.aiforia.com",
    "https://insights.ieg.ac.uk",
    "https://link.idesignedu.org",
    "https://happeo.aconnect.net",
    "https://chiliverse.chiliz.com",
    "https://intranet.mermaidsuk.org.uk",
    "https://paperwork.papercut.com",
    "https://connected.makingscience.com",
    "https://intranet.gwiu.gwi.com",
    "https://comms.my.itv.com",
    "https://happeo.mbamfunds.com",
    "https://beehive.bumble.team",
    "https://wanasoko.wasoko.com",
    "https://zoomdrainhub.com",
    "https://insider.techstreet.com",
    "https://swhaven.com",
    "https://insight.akumin.com",
    "https://happeo.fireflycapital.com",
    "https://onehub.henryscheinone.com",
    "https://dnet.decathlon.com",
    "https://intranet.artec3d.com",
    "https://aesaraforum.com",
    "https://altro-connect.be",
    "https://paperwork.papercut.software",
    "https://academyhub.sdsu.edu",
    "https://intra.falstaff.com",
    "https://intranet.siat-group.com",
    "https://conet.conrad.com",
    "https://tadohub.com",
    "https://team.blueprintstudios.com",
    "https://zegopedia.zego.com",
    "https://connect.dev.randstad.com",
    "https://connect.salesmanago.com",
    "https://connect.fearisnotlove.ca",
    "https://happeo.beyondco.com",
    "https://inteam.unvrs.io",
    "https://extranet.supercell.com",
    "https://canteen.danielreade.com",
    "https://spherionlink.com",
    "https://uno.tenable.com",
    "https://one.tenable.com",
    "https://intea.teaspa.it",
    "https://greenhouse.joinlavender.com"
  ],
};
const devConf = {
  CLIENT_ID:
    "1088684324598-4nr7b52imqt9em31660cukhlf3v9en6u.apps.googleusercontent.com",
  ALLOWED_ORIGINS: [
    "https://staging.unvrs.io",
    "https://integrations-staging.unvrs.io",
    "https://app.happeo.com",
    "http://localhost:3002",
    "http://localhost:8000",
  ],
};

const isProd = window.location.host.includes("happeo.com");
const configuration = isProd ? prodConf : devConf;

export const ALLOWED_ORIGINS = configuration.ALLOWED_ORIGINS;
export const CLIENT_ID = configuration.CLIENT_ID;
export const API_KEY = configuration.API_KEY;
export const ASSETS_ROOT = "https://cdn.happeo.com/misc";
export const AFTER_AUTH_TIMEOUT = 1000;
export const SCOPES = {
  basic: "profile",
  all: "profile https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/gmail.settings.basic",
  search: "profile https://www.googleapis.com/auth/gmail.readonly",
  settings: "profile https://www.googleapis.com/auth/gmail.settings.basic",
  inbox: "profile https://www.googleapis.com/auth/gmail.readonly",
};
export const DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest",
];
export const SENTRY_CONF = {
  dsn: "https://57d5f1bcd97b4f7da14317c26b035907@sentry.io/1844396",
};

export const ROUTE_PATHNAMES = {
  enableIntegrations: "/gmail/enableIntegration",
  authListenerHidden: "/gmail/authorize",
  outOfOffice: "/gmail/outOfOffice",
  gmailSearch: "/gmail/search",
  gmailInbox: "/gmail/inbox",
  oauthCallback: "/oauth/callback",
};

export const ROUTE_SCOPE_MAP = {
  [ROUTE_PATHNAMES.gmailSearch]: SCOPES.search,
  [ROUTE_PATHNAMES.outOfOffice]: SCOPES.settings,
  [ROUTE_PATHNAMES.gmailInbox]: SCOPES.inbox,
};
