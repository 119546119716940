export const GSUITE_INTEGRATION_READY = "GSUITE_INTEGRATION_READY";
export const IDENTIFY_PARENT = "IDENTIFY_PARENT";
export const AUTHORIZATION_STATUS = "AUTHORIZATION_STATUS";
export const RESIZE_EVENT = "RESIZE_EVENT";
export const RECEIVED_RESULTS = "RECEIVED_RESULTS";
export const SEARCH_GMAIL = "SEARCH_GMAIL";
export const GET_VACATION_MESSAGE = "GET_VACATION_MESSAGE";
export const LIST_INBOX = "LIST_INBOX";
export const ANALYTICS_EVENT = "ANALYTICS_EVENT";
export const ENABLE_INTEGRATION = "ENABLE_INTEGRATION";
export const AUTHORIZE_EVENT = "AUTHORIZE_EVENT";
export const AUTHORIZE_EVENT_DONE = "AUTHORIZE_EVENT_DONE";
export const NEW_ACCESS_TOKEN = "NEW_ACCESS_TOKEN";
