import React, { useEffect, useState } from "react";

export const OauthCallback = () => {
  const [hash, setHash] = useState("");

  useEffect(() => {
    if (window.location.hash && window.location.hash !== hash) {
      setHash(window.location.hash);
      const accessTokenQueryParams = new URLSearchParams(
        window.location.hash.substring(1)
      );

      // random key is redundant but very helpful in debugging and keeping track
      // when multiple iframes/gmail widgets are on same page
      const accessTokenObject = { key: window.randomKey };

      accessTokenQueryParams.forEach((value, key) => {
        accessTokenObject[key] = value;
      });
      window.parent.postMessage({ NEW_ACCESS_TOKEN: accessTokenObject });
    }
  }, [hash]);

  return (
    <div>
      <h1>callBack</h1>
    </div>
  );
};
