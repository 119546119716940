import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { Spacer } from "@happeouikit/layout";
import { gray04, gray07 } from "@happeouikit/colors";
import { Banner } from "@happeouikit/banners";
import AuthorizationButton from "./AuthorizationButton";
import { BodyUI, TextDelta } from "@happeouikit/typography";
import { IconEmail } from "@happeouikit/icons";
import { FormattedMessage } from "react-intl.macro";
import messages from "./messages";

import {
  RESIZE_EVENT,
  AUTHORIZATION_STATUS,
} from "../../services/iframeCommunication/actions";
import { emitToParent } from "../../services/iframeCommunication";
import { useIntl } from "react-intl";

const ReadMore = () => {
  const intl = useIntl();

  return (
    <FormattedMessage
      id="authorize.readMore.content"
      defaultMessage="Read more about this intergration {link}."
      values={{
        link: (
          <a
            href="https://www.happeo.com/knowledge/gmail-integration"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            {intl.formatMessage(messages.readMoreHere)}
          </a>
        ),
      }}
    />
  );
};

const AuthorizationMessage = ({
  title,
  content,
  callback,
  scope,
  type = "ad",
}) => {
  const containerElement = useRef(null);

  useEffect(() => {
    emitToParent({ [AUTHORIZATION_STATUS]: { authorized: false } });

    if (containerElement && containerElement.current) {
      emitToParent({
        [RESIZE_EVENT]: {
          height: `${containerElement.current.offsetHeight + 2}px`,
        },
      });
    }
  });

  const authorizedCallback = () => {
    // Set timeout to wait for XXXXms to give some time to Google's servers to propagate the perimssions
    emitToParent({ [AUTHORIZATION_STATUS]: { authorized: true } });
    callback();
  };

  if (type === "banner") {
    return (
      <BannerContainer ref={containerElement}>
        <Banner
          state={"warn"}
          title={title}
          description={content}
          action={
            <AuthorizationButton
              type={"link"}
              scope={scope}
              callback={authorizedCallback}
            />
          }
        ></Banner>
      </BannerContainer>
    );
  }

  return (
    <AuthorizationContainer ref={containerElement}>
      <IconEmail fill={gray07} />
      <Spacer height={"16px"} />
      <TextDelta>{title}</TextDelta>
      <Spacer height={"8px"} />
      <BodyUI color={gray04}>
        {content} <ReadMore />
      </BodyUI>
      <Spacer height={"16px"} />
      <AuthorizationButton scope={scope} callback={authorizedCallback} />
    </AuthorizationContainer>
  );
};
const BannerContainer = styled.div`
  padding: 4px;
`;
const AuthorizationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 450px;
  text-align: center;
  svg {
    width: 74px;
    height: 74px;
  }
`;

export default AuthorizationMessage;
