import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { list, isAuthorizationRequired } from "../../services/GmailApi/api";
import { trackEvent, INBOX_LIST, UNAUTHORIZED_INBOX } from "../AppAnalytics";
import GmailInboxItem from "./GmailInboxItem";
import {
  LIST_INBOX,
  RESIZE_EVENT,
} from "../../services/iframeCommunication/actions";
import {
  authorizeEvent,
  emitToParent,
} from "../../services/iframeCommunication";
import { IconExternalLink } from "@happeouikit/icons";
import { BodyUI } from "@happeouikit/typography";
import { Line300, shadow300 } from "@happeouikit/theme";
import { LinkExternal } from "@happeouikit/form-elements";
import { Spacer } from "@happeouikit/layout";
import { active, lighten } from "@happeouikit/colors";
import { AuthorizationMessage } from "../Authorization";
import messages from "./messages";
import { NoResults } from "../NoResults";
import { useIntl } from "react-intl";
import { gisUtils } from "../../services/googleIdentity/GisUtils";

const SearchResults = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [options, setOptions] = useState({
    ready: true,
    maxItems: 10,
  });
  const [results, setResults] = useState({ items: [] });
  const [requestAuthorization, setRequestAuthorization] = useState(false);
  const containerElement = useRef(null);
  const intl = useIntl();

  useEffect(() => {
    function listener(event) {
      authorizeEvent(event).then((allowed) => {
        if (!allowed) return;
        const { data } = event;
        if (data[LIST_INBOX]) {
          setOptions({
            ready: true,
            ...data[LIST_INBOX],
          });
        }
      });
    }
    window.addEventListener("message", (event) => listener(event), false);
    return () => {
      window.removeEventListener("message", listener);
    };
  }, []);

  useEffect(() => {
    if (!options.ready) return;

    setLoading(true);
    setError(false);

    if (containerElement && containerElement.current) {
      emitToParent({
        [RESIZE_EVENT]: {
          height: `${containerElement.current.offsetHeight}px`,
        },
      });
    }

    if (gisUtils.isLoggedIn) {
      gisUtils.onReady(() => {
        list(
          {},
          {
            ...options,
            labelIds: ["INBOX"],
          }
        )
          .then((data) => {
            setResults(data);
            trackEvent(INBOX_LIST, {}, {});
            setTimeout(() => {
              if (containerElement && containerElement.current) {
                emitToParent({
                  [RESIZE_EVENT]: {
                    height: `${containerElement.current.offsetHeight}px`,
                  },
                });
              }
            }, 1);
          })
          .catch((error) => {
            if (isAuthorizationRequired(error)) {
              setRequestAuthorization(true);
              trackEvent(UNAUTHORIZED_INBOX, {}, { onlyUniverse: true });
            } else {
              setError(true);
            }
            setOptions((options) => ({
              ...options,
              ready: false,
            }));
          })
          .finally(() => {
            setLoading(false);
          });
      });
    } else {
      setRequestAuthorization(true);
      trackEvent(UNAUTHORIZED_INBOX, {}, { onlyUniverse: true });
      setLoading(false);
    }
  }, [options, requestAuthorization]);

  const authorizationCallback = () => {
    setRequestAuthorization(false);
    setOptions((options) => ({
      ...options,
      ready: true,
    }));
    emitToParent({ [RESIZE_EVENT]: { height: "0px" } });
  };

  if (requestAuthorization) {
    return (
      <InfoContainer>
        <AuthorizationMessage
          title={intl.formatMessage(messages.authorizeTitle)}
          content={intl.formatMessage(messages.authorizeContent)}
          scope={"inbox"}
          callback={authorizationCallback}
        />
      </InfoContainer>
    );
  }

  if (error) {
    return (
      <InfoContainer>
        <BodyUI>{intl.formatMessage(messages.genericError)}</BodyUI>
      </InfoContainer>
    );
  }

  const showLoadingFromEmptyState = loading && results.items.length === 0;
  const showLoadingWithResults = loading && results.items.length > 0;

  return (
    <Container ref={containerElement}>
      {showLoadingWithResults && (
        <LoadingContainer>
          <LoadingContainerInner>
            <BodyUI>{intl.formatMessage(messages.updatingInbox)}</BodyUI>
          </LoadingContainerInner>
        </LoadingContainer>
      )}
      <MessageContainer>
        {showLoadingFromEmptyState &&
          ["1", "2", "3", "4"].map((item) => (
            <MessageItemContainer key={item}>
              <GmailInboxItem loading={true} />
            </MessageItemContainer>
          ))}
        {!showLoadingFromEmptyState &&
          results.items &&
          results.items.map((message) => (
            <MessageItemContainer key={message.id}>
              <GmailInboxItem message={message} />
              <Line300 />
            </MessageItemContainer>
          ))}
        {!loading && results.items && results.items.length === 0 && (
          <NoResults
            title={intl.formatMessage(messages.noResultsTitle)}
            description={intl.formatMessage(messages.noResultsDescription)}
          />
        )}
      </MessageContainer>

      <LinkExternal href={"https://mail.google.com"}>
        <LinkContainer>
          <IconExternalLink fill={active} />
          <Spacer width={"6px"} />
          <BodyUI bold color={active}>
            {intl.formatMessage(messages.openGmail)}
          </BodyUI>
        </LinkContainer>
      </LinkExternal>
    </Container>
  );
};

const InfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Container = styled.div``;
const MessageContainer = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;
const LoadingContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const LoadingContainerInner = styled.div`
  padding: 8px;
  background-color: ${lighten(active, 0.8)};
  border-radius: 6px;
  box-shadow: ${shadow300};
`;
const MessageItemContainer = styled.li``;
const LinkContainer = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
`;

export default SearchResults;
