import React from "react";
import { ButtonPrimary } from "@happeouikit/buttons";
import {
  requestAllScopes,
  requestSearchScopes,
  requestSettingsScopes,
  requestInboxScopes,
} from "../../services/GmailApi/initialisation";
import { Link } from "@happeouikit/form-elements";
import messages from "./messages";
import { useIntl } from "react-intl";

const AuthorizationButton = ({ scope = "all", callback, type }) => {
  const intl = useIntl();

  const authorize = () => {
    let fn = () => {
      console.error("Auth scope missing");
    };
    if (scope === "all") {
      fn = requestAllScopes;
    }

    if (scope === "search") {
      fn = requestSearchScopes;
    }

    if (scope === "settings") {
      fn = requestSettingsScopes;
    }

    if (scope === "inbox") {
      fn = requestInboxScopes;
    }

    fn(callback);
  };

  if (type === "link") {
    return (
      <Link href="#" onClick={authorize}>
        {intl.formatMessage(messages.authorizeButton)}
      </Link>
    );
  }

  return (
    <ButtonPrimary
      size={"small"}
      text={intl.formatMessage(messages.authorizeButton)}
      onClick={authorize}
    />
  );
};
export default AuthorizationButton;
