import { defineMessages } from "react-intl.macro";
export default defineMessages({
  authorizeButton: {
    id: "authorize.button",
    defaultMessage: "Authorize integration"
  },
  readMore: {
    id: "authorize.readMore.content",
    defaultMessage: "Read more about this intergration {link}."
  },
  readMoreHere: {
    id: "authorize.readMore.here",
    defaultMessage: "here"
  }
});
